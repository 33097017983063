<template>
  <div style="margin: 10px;">
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <work-subject :p_showDetails="{'type': 'selected', 'subject_name': 'treatmentdatechangepage'}"></work-subject>
        <hr>
        <h3>Komponent Kullanımı</h3>
        <hr>
        <treatment-date-change :p_treatmentData="d_treatmentData" :p_treatmentDayDate="d_treatmentDayDate" :p_day="'2022-01-01'"></treatment-date-change>
        <hr>
        <h3>Komponent açılan modalde görünüm</h3>
        <h6>Butona tıklayarak açılan modalde tarih değiştirme komponentini görüntüleyebilirsiniz</h6>
        <b-row>
          <b-col sm="12" lg="12">
            <b-button @click="f_openTreatmentDateChangeModal()" variant="primary">Tarih Düzenleme Ekranı</b-button>
          </b-col>
        </b-row>
        <hr>
        <template v-for="(code_example, code_example_index)  in d_componentExample.schema_drugs">
          <h3> {{ code_example.name }} </h3>
          <div class="code-text">{{ code_example.code }}</div>
        </template>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'PatientTreatmentDrugs'}"></sql-database>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'PatientTreatmentDrugsApplication'}"></sql-database>
        <hr>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
    <b-modal v-if="d_treatmentDayDate.show" v-model="d_treatmentDayDate.show" centered class="modal-success" @ok="d_treatmentDayDate.show = false" ok-variant="success" hide-footer size="md">
      <b-row>
        <b-col cols="12">
          <treatment-date-change :p_treatmentData="d_treatmentData" :p_treatmentDayDate="d_treatmentDayDate" :p_day="'2022-01-01'"></treatment-date-change>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <b-button variant="danger" @click="d_treatmentDayDate.show = false">Kapat</b-button>
          <b-button variant="success" @click="f_applyDayDateChange()">Tarih Değişimini Uygula</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  default as WorkSubject
} from '@/components/widgets/WorkSubject';
import {
  default as TreatmentDateChange
} from '@/components/widgets/TreatmentDateChange';
import {
  default as SqlDatabase
} from '@/components/widgets/SqlDatabase';
import { mapGetters } from 'vuex';
export default {
  name: 'TreatmentDateChangePage',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {
    WorkSubject,
    TreatmentDateChange,
    SqlDatabase,
  },
  props: {},
  data () {
    return {
      d_treatmentDayDate: {
        'show': false,
        'new': '',
        'offset': 0,
        'reason': []
      },
      d_treatmentList: require('@/example_data/treatment_list').example_data,
      d_patientData: require('@/example_data/patient_data').example_data,
      d_treatmentData: require('@/example_data/treatment_data').example_data,
      d_componentExample: {
        'schema_drugs': [
          { 'code': '<template v-for="(day, day_index) in d_treatmentData.drug_day_order"> \n <treatment-day :p_treatmentData="d_treatmentData" :p_day="day"></treatment-day> \n </template>', 'name': 'Örnek komponent kullanımı' },
        ]
      },
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_applyDayDateChange: function () {
      console.log('f_applyDayDateChange ...');
    },
    f_openTreatmentDateChangeModal: function () {
      this.d_treatmentDayDate = {
        'show': true,
        'new': '',
        'offset': 0,
        'reason': []
      };
    }
  },
  watch: {}
}

</script>

<style type="text/css">


</style>

