<template>
  <div>
    <b-row>
      <b-col sm="12" lg="6">
        <strong>Mevcut tarih</strong>
      </b-col>
      <b-col sm="12" lg="6">
        {{ f_dateFormat(p_day) }}
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="6">
        <strong>Yeni tarih</strong>
      </b-col>
      <b-col sm="12" lg="6">
        <input type="date" class="form-control" v-model="p_treatmentDayDate.new">
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="6">
        <strong>Gün değişimi kaç gün</strong>
      </b-col>
      <b-col sm="12" lg="6">
        <input type="number" class="form-control" min="0" name="offset" v-model="p_treatmentDayDate.offset">
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="6">
        <strong> Tarih değişim sebebi </strong>
      </b-col>
      <b-col sm="12" lg="6">
        <b-form-checkbox-group v-model="p_treatmentDayDate.reason">
          <template v-for="(reason, reason_index) in d_dateChangeReasonList">
            <b-form-checkbox :key="'date_change' + reason_index" :value="reason.id">
              {{ reason.label }}
            </b-form-checkbox>
          </template>
        </b-form-checkbox-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="6">
        <strong>Not</strong>
      </b-col>
      <b-col sm="12" lg="6">
        <textarea class="form-control" v-model="p_treatmentDayDate.note"></textarea>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  name: 'TreatmentDateChange',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {},
  props: {
    p_treatmentData: {
      type: Object,
      required: true
    },
    p_treatmentDayDate: {
      type: Object,
      required: true
    },
    p_day: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      d_dateChangeReasonList: require('@/tables/DateChangeReason').options,
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_dateFormat: function (date) {
      let formatted_date = '';
      if (date) {
        formatted_date = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
      }
      return formatted_date;
    },
  },
  watch: {}
}

</script>

<style type="text/css">

</style>

